/* eslint-disable */
import { GemBoxPdfViewer } from '@gembox/pdfviewer';
import '@gembox/pdfviewer/dist/es/pdfviewer.css';
import React, { useEffect, useRef } from 'react';

interface PdfViewerProps {
    data: string;
    style?: React.CSSProperties;
}

interface PdfViewerRef {
    setPage: (pageNumber: number) => void;
}

const PdfViewer = ({ data, style }: PdfViewerProps, ref: React.ForwardedRef<PdfViewerRef>) => {
    const viewerElement = useRef<HTMLDivElement>(null);
    const urlRef = useRef<string | undefined>();
    const viewerRef = useRef<any>(null);

    useEffect(() => {
        const licenseKey = process.env.REACT_APP_GEMBOX_LICENSE_KEY;
        if (!licenseKey) {
            console.warn('GemBox license key not found in environment variables');
        }
        GemBoxPdfViewer.setLicense(licenseKey || 'FREE-LIMITED-KEY');
    }, []);

    useEffect(() => {
        if (!data || !viewerElement.current) {
            console.error('PDF data is empty or invalid');
            return;
        }

        const initializeViewer = async () => {
            try {
                const binary = atob(data);
                const binaryData = new Uint8Array(binary.length);
                for (let i = 0; i < binary.length; i++) {
                    binaryData[i] = binary.charCodeAt(i);
                }

                const blob = new Blob([binaryData], { type: 'application/pdf' });
                urlRef.current = URL.createObjectURL(blob);

                viewerRef.current = await GemBoxPdfViewer.create({
                    container: viewerElement.current as HTMLElement,
                    visibility: {
                        sideMenu: true,
                        open: false,
                        print: true,
                        download: true,
                        pageNavigation: true,
                        panMode: true,
                        zoom: true,
                        search: true,
                    },
                    initialDocument: urlRef.current,
                });

                viewerRef.current.setZoom('fitPage');
            } catch (error) {
                console.error('Error creating PDF viewer:', error);
            }
        };

        initializeViewer();

        return () => {
            if (urlRef.current) {
                const urlToRevoke = urlRef.current;
                setTimeout(() => {
                    URL.revokeObjectURL(urlToRevoke);
                }, 100);
                urlRef.current = undefined;
            }
        };
    }, [data]);

    useEffect(() => {
        if (ref && 'current' in ref) {
            ref.current = {
                setPage: (pageNumber: number) => {
                    if (viewerRef.current) {
                        viewerRef.current.setCurrentPage(pageNumber);
                    }
                },
            };
        }
    }, [ref]);

    return (
        <div
            ref={viewerElement}
            style={{
                height: 'calc(90vh - 40px)',
                width: '100%',
                ...style,
            }}
        ></div>
    );
};

export default React.forwardRef(PdfViewer);
