import {
    Button,
    Dialog,
    DialogBody,
    DialogSurface,
    DialogTitle,
    DialogTrigger,
    Spinner,
    Text,
    makeStyles,
    mergeClasses,
    shorthands,
} from '@fluentui/react-components';
import { useEffect, useRef, useState } from 'react';
import { useChat } from '../../../libs/hooks';
import { IChatMessage } from '../../../libs/models/ChatMessage';
import PdfViewer from '../../shared/GemboxPdfViewer';
import { CitationCard } from './CitationCard';

interface DocumentViewerProps {
    documentId: string;
    chatId: string;
    isOpen: boolean;
    onClose: () => void;
    message?: IChatMessage;
    initialPage?: number;
}

const useStyles = makeStyles({
    dialogSurface: {
        width: '90vw',
        height: '90vh',
        maxWidth: '1600px',
        maxHeight: '90vh',
    },
    closeButton: {
        opacity: 0,
        position: 'absolute',
        right: '16px',
        top: '16px',
    },
    dialogBody: {
        height: 'calc(90vh - 60px)',
        ...shorthands.padding('0'),
        display: 'flex',
    },
    contentContainer: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'row',
        flexShrink: 0,
    },
    contentContainerWithCards: {
        justifyContent: 'flex-start',
    },
    contentContainerNoCards: {
        justifyContent: 'center',
    },
    pdfContainer: {
        height: '100%',
        position: 'relative',
    },
    pdfContainerWithCards: {
        flexBasis: '70%',
        flexGrow: 0,
        flexShrink: 0,
        minWidth: 0,
    },
    pdfContainerNoCards: {
        flexGrow: 1,
        minWidth: '800px',
        maxWidth: '1600px',
    },
    cardsContainer: {
        flexBasis: '30%',
        flexGrow: 0,
        flexShrink: 0,
        height: '100%',
        overflowY: 'auto',
        ...shorthands.padding('16px'),
        display: 'flex',
        flexDirection: 'column',
        ...shorthands.gap('12px'),
        minWidth: 0,
        maxHeight: '100%',
        boxSizing: 'border-box',
    },
    card: {
        width: '100%',
    },
    loadingContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100%',
        ...shorthands.gap('12px'),
    },
});

const DocumentViewer = ({ documentId, chatId, isOpen, onClose, message, initialPage }: DocumentViewerProps) => {
    const styles = useStyles();
    const [documentData, setDocumentData] = useState<string>();
    const chat = useChat();
    const pdfViewerRef = useRef<any>(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const fetchDocument = async () => {
            setLoading(true);
            try {
                const bytes = message?.id
                    ? await chat.getDocumentBytesCitations(chatId, documentId, message.id)
                    : await chat.getDocumentBytes(chatId, documentId);

                if (!bytes) throw new Error('Failed to fetch document');
                setDocumentData(bytes);
            } catch (error) {
                console.error('Error fetching document:', error);
            } finally {
                setLoading(false);
            }
        };

        if (documentId && chatId) {
            void fetchDocument();
        }
    }, [documentId, chatId, message]);

    useEffect(() => {
        if (documentData && initialPage && !loading && pdfViewerRef.current) {
            // This is a bit scuffed, maybe look at fixing this later
            const timer = setTimeout(() => {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
                pdfViewerRef.current.setPage(initialPage);
            }, 500);

            return () => {
                clearTimeout(timer);
                return undefined;
            };
        }
        return undefined;
    }, [documentData, initialPage]);

    return (
        <Dialog
            open={isOpen}
            onOpenChange={(_, { open }) => {
                if (!open) {
                    onClose();
                }
            }}
        >
            <DialogSurface className={styles.dialogSurface}>
                <DialogTitle>
                    <DialogTrigger disableButtonEnhancement>
                        <Button onClick={onClose} className={styles.closeButton} aria-hidden="false" tabIndex={0}>
                            Close
                        </Button>
                    </DialogTrigger>
                </DialogTitle>
                <DialogBody className={styles.dialogBody}>
                    <div
                        className={mergeClasses(
                            styles.contentContainer,
                            message ? styles.contentContainerWithCards : styles.contentContainerNoCards,
                        )}
                    >
                        <div
                            className={mergeClasses(
                                styles.pdfContainer,
                                message ? styles.pdfContainerWithCards : styles.pdfContainerNoCards,
                            )}
                        >
                            <PdfViewer
                                ref={pdfViewerRef}
                                data={documentData ?? ''}
                                style={{
                                    position: 'absolute',
                                    width: '100%',
                                    height: '100%',
                                    margin: 0,
                                    display: !documentData || loading ? 'none' : 'block',
                                }}
                            />
                            {(!documentData || loading) && (
                                <div className={styles.loadingContainer}>
                                    {message && (
                                        <Text>Loading document with citations, this can take a few seconds...</Text>
                                    )}
                                    <Spinner size="medium" />
                                </div>
                            )}
                        </div>
                        {message?.citations && (
                            <div className={styles.cardsContainer}>
                                {message.citations
                                    .filter(
                                        (citation) =>
                                            message.citationsUsed?.[citation.citationRef] !== undefined &&
                                            citation.documentId === documentId,
                                    )
                                    .map((citation, index) => (
                                        <CitationCard
                                            key={`document-citation-${index}`}
                                            citation={citation}
                                            onClick={() => {
                                                const page = message.citationsUsed?.[citation.citationRef];
                                                if (page && pdfViewerRef.current) {
                                                    // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
                                                    pdfViewerRef.current.setPage(page);
                                                }
                                            }}
                                        />
                                    ))}
                            </div>
                        )}
                    </div>
                </DialogBody>
            </DialogSurface>
        </Dialog>
    );
};

export default DocumentViewer;
