import { Badge, Card, CardHeader, Text, makeStyles } from '@fluentui/react-components';
import { Citation } from '../../../libs/models/ChatMessage';

const useStyles = makeStyles({
    card: {
        display: 'flex',
        width: '100%',
        height: 'fit-content',
    },
});

interface CitationCardProps {
    citation: Citation;
    onClick?: () => void;
}

export const CitationCard: React.FC<CitationCardProps> = ({ citation, onClick }) => {
    const styles = useStyles();

    return (
        <Card
            className={styles.card}
            size="small"
            onClick={onClick}
            style={{ cursor: onClick ? 'pointer' : 'default' }}
        >
            <CardHeader
                image={
                    <Badge shape="rounded" appearance="outline" color="informative">
                        {citation.citationRef}
                    </Badge>
                }
                header={<Text weight="semibold">{citation.sourceName}</Text>}
            />
        </Card>
    );
};
